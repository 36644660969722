import { amgApi } from '@/service/axios'
class ClientService {
  async getClients(body, page) {
    try {
      const data = await amgApi.post(`clients/search-clients?page=${page}`, body)
      return data
    } catch (error) {
      console.log('Something went wrong on getClients:', error)
      throw error
    }
  }
  async getClientsQuality(body, page) {
    try {
      const data = await amgApi.post(`clients/search-clients-quality?page=${page}`, body)
      return data
    } catch (error) {
      console.log('Something went wrong on getClientsQuality:', error)
      throw error
    }
  }
  async programs() {
    const data = await amgApi.get("/commons/programs");
    return data;
  }

  // Clients
  async usersPrograms(params) {
    const data = await amgApi.post("/commons/users-programs", params);
    return data.data;
  }

  async usersAllPrograms(params) {
    const data = await amgApi.post("/commons/users-all-programs", params);
    return data;
  }

  // Clients
  async moveList(params) {
    try {
      const data = await amgApi.post("/clients/move-list", params);
      return data;
    } catch (error) {
      console.log('Something went wrong on moveList:', error)
      throw error
    }
  }

  async getClientsCounter(params) {
    try {
      const { data, status } = await amgApi.post(
        "/clients/count-client-tax-main",
        params
      );
      return { data, status };
    } catch (error) {
      throw error;
    }
  }

  async getTrackingData(params) {
    try {
      const { data, status } = await amgApi.post(
        "/clients/tracking-tax-main",
        params
      );
      return { data, status };
    } catch (error) {
      throw error;
    }
  }

  async usersAllPrograms(params) {
    const data = await amgApi.post("/commons/users-all-programs", params);
    return data;
  }

  async searchClientPayment(params) {
    const data = await amgApi.post("/clients/search-client-payment", params);
    return data;
  }
  async getAllAdvisors(payload) {
    const data = await amgApi.post("/program/get-program-users", payload);
    return data.data;
  }
}
export default new ClientService()